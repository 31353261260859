'use client';

import { Tooltip } from 'react-tooltip';

const TooltipWrapper = () => {
  return (
    <Tooltip
      id="general-tooltip"
      className="messenger-tooltip"
      classNameArrow="messenger-tooltip-arrow"
    />
  );
};

export default TooltipWrapper;
