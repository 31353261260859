export const safePaths = [
  'static',
  '_next',
  'api',
  'register',
  'login',
  'retool-login',
  'forgot-password',
  'reset-password',
  'accept-invite',
  'aibot-iframe',
  'embed.js',
  'assets',
  'privacy-policy-summarizer',
  'free-tools',
  'bar-chart-generator-ai',
  'bar-graph-maker',
  'pie-chart-generator-ai',
  'line-chart-generator-ai',
  'table-generator-ai',
  'column-chart-generator-ai',
  'tweet-generator-ai',
  'ai-bot',
  'meta-information-generator-ai',
  'instagram-post-generator-ai',
  'youtube-post-generator-ai',
  'create-whatsapp-links',
  'page-speed-insights',
  'preview',
  'image-alt-text-generator-ai',
  'ai-blog-outline-creator',
  'ai-blog-post-ideas-generator',
  'ai-business-name-generator',
  'ai-competitor-analysis-tool',
  'ai-document-summarizer',
  'ai-email-generator-tool',
  'ai-product-description-generator',
  'ai-resume-analyzer',
  'ai-sentence-rewriter-tool',
  'ai-slogan-generator',
  'ai-subject-line-generator',
  'chatbot-name-generator',
];

export const posthogDisabledPages = [
  'static',
  '_next',
  'api',
  'register',
  'login',
  'retool-login',
  'forgot-password',
  'reset-password',
  'accept-invite',
  'aibot-iframe',
  'embed.js',
  'assets',
  'ai-bot',

  // Free tools and generators
  'privacy-policy-summarizer',
  'line-graph-maker',
  'bar-graph-maker',
  'pie-chart-maker',
  'table-chart-maker',
  'column-chart-maker',
  'fake-instagram-post-generator',
  'youtube-video-post-generator',
  'fake-tweet-generator',
  'meta-title-description-generator',
  'custom-bar-graph-maker',
  'youtube-title-description-generator',

  // PDF tools
  'merge-pdf',
  'rotate-pdf-free',
  'pdf-password-remover-tool',
  'organize-pdf',
  'repair-pdf',
  'compress-pdf',
  'extract-pages-from-pdf',
  'pdf-to-grayscale',
  'jpg-to-pdf',
  'txt-to-pdf',
  'how-to-convert-pdf-to-word-document',
  'pdf-to-powerpoint',
  'pdf-to-txt',
  'convert-pdf-to-zip',

  // Pricing calculators
  'gpt-4-turbo-pricing-calculator',
  'claude-pricing-calculator',
  'azure-openai-token-calculator',
  'gpt-3-5-turbo-pricing-calculator',
  'llama-3-70b-pricing-calculator',
  'mistral-medium-token-calculator',
  'claude-opus-pricing-calculator',
  'anthropic-claude-3-haiku-pricing-calculator',
  'anthropic-claude-3-sonnet-pricing-calculator',
  'gpt-4o-pricing-calculator',
  'palm-2-pricing-calculator',
  'command-cohere-pricing-calculator',

  // Utility tools
  'whatsapp-link-generator',
  'ai-qr-code-generator',
  'website-health-checker',

  // AI tools
  'chatbot-name-generator',
  'ai-email-writer',
  'ai-sentence-rewriter',
  'business-name-generator',
  'ai-competitor-analysis',
  'slogan-generator',
  'summary-generator',
  'ai-resume-checker',
  'ai-text-humanizer',
  'ai-faq-generator',
  'ai-sentence-expander',
  'ai-answer-generator',
];

export const freeTools = [
  '/privacy-policy-summarizer',
  '/bar-chart-generator-ai',
  '/pie-chart-generator-ai',
  '/line-chart-generator-ai',
  '/column-chart-generator-ai',
  '/bar-graph-maker',
  '/table-generator-ai',
  '/meta-information-generator-ai',
  '/tweet-generator-ai',
  '/instagram-post-generator-ai',
  '/youtube-post-generator-ai',
  '/api-pricing-calculator',
  '/create-whatsapp-links',
  '/page-speed-insights',
  '/image-alt-text-generator-ai',
  '/chatbot-name-generator',
  '/ai-blog-outline-creator',
  '/ai-blog-post-ideas-generator',
  '/ai-business-name-generator',
  '/ai-competitor-analysis-tool',
  '/ai-document-summarizer',
  '/ai-email-generator-tool',
  '/ai-product-description-generator',
  '/ai-resume-analyzer',
  '/ai-sentence-rewriter-tool',
  '/ai-slogan-generator',
  '/ai-subject-line-generator',
  '/ai-text-humanizer',
  '/ai-faq-generator',
  '/ai-answers-generator',
  '/ai-text-expander',
  '/ai-cover-letter-generator',
  '/ai-job-description-generator',
  '/ai-reference-letter-generator',
];
