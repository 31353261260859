'use client';

import React from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const ClientToastContainer = () => {
  return (
    <ToastContainer
      position="top-right"
      hideProgressBar
      closeButton={false}
      closeOnClick={true}
    />
  );
};

export default ClientToastContainer;
