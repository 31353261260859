'use client';

import { createContext, useContext, useState } from 'react';

export const ModalContext = createContext({
  activeModal: { id: null, data: null, onSuccess: null },
  openModal: <T extends unknown>({
    id,
    data,
    onSuccess,
  }: {
    id: string;
    data?: { [x in string]: string | number | object | boolean };
    onSuccess?: (value: T) => void;
  }) => {},
  closeModal: () => {},
});

export function ModalProvider({ children }) {
  const [activeModal, setActiveModal] = useState({
    id: null,
    data: null,
    onSuccess: null,
  });

  const openModal = <T extends unknown>({
    id,
    data,
    onSuccess,
  }: {
    id: string;
    data?: { [x in string]: string | number | object | boolean };
    onSuccess?: (value: T) => void;
  }): void => {
    setActiveModal({
      id,
      data,
      onSuccess,
    });
  };

  const closeModal = () => {
    setActiveModal((prev) => ({
      ...prev,
      id: null,
    }));

    // Clear the modal data after 1 second
    setTimeout(() => {
      setActiveModal({
        id: null,
        data: null,
        onSuccess: null,
      });
    }, 500);
  };

  return (
    <ModalContext.Provider
      value={{
        activeModal,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export const useModalContext = () => useContext(ModalContext);
