'use client';
import type { User } from '@/types';

import React, { useCallback, useEffect } from 'react';

import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { SESSION_UPDATE_COUNT } from '@/config/dashboard';
import { FREE_PLAN_ID } from '@/constants/plan';

/**
 * We keep the plan data in the session,
 * we have updated the plan limits,
 * so we need to update the sessions that have not been updated.
 */

const CURRENT_COUNT = SESSION_UPDATE_COUNT || '1';

const checkIsUpdatedBefore = (value?: string) => {
  if (!value) return false;
  if (typeof value !== 'string') return false;

  const isInvalidNumber = Number.isNaN(value);

  if (isInvalidNumber) return false;

  return Number(value) >= Number(CURRENT_COUNT);
};

const SessionChecker = () => {
  const router = useRouter();
  const { update, data: session } = useSession();

  const user = session?.user as User;
  const isPaidUser = user ? user?.workspace?.plan?.id !== FREE_PLAN_ID : false;

  const updateSession = useCallback(async () => {
    await update({ action: 'update' });

    localStorage.setItem('lc-session-updated', CURRENT_COUNT);

    router.refresh();
  }, [router, update]);

  useEffect(() => {
    try {
      if (!isPaidUser) return;

      const lastUpdateCount = localStorage.getItem('lc-session-updated');
      const isUpdatedBefore = checkIsUpdatedBefore(lastUpdateCount);
      if (isUpdatedBefore) return;

      updateSession();
    } catch (error) {
      console.error('[SessionChecker] Error', error);
    }
  }, [isPaidUser, updateSession]);

  return <div id="session-checker"></div>;
};

export default SessionChecker;
